const persianToEnglishMap: { [key: string]: string } = {
    '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', 
    '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9',
    '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4', 
    '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
};

export default (input: string): string => {
    if (!input) return '0';
    const result = input.replace(/[۰-۹٠-٩]/g, function(match) {
        return persianToEnglishMap[match];
    });
    return result;
}